.single-page-right-content h4 {
 font-size: 2em;
 margin: 0;
 background: linear-gradient(90deg, red, #80f);
 background-clip: text;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.single-page-btn button {
 margin: 1.3em 0;
 background: #a070dd;
}
.single-page-details {
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.single-page-details p,
.single-page-details h2 {
 font-size: 1em;
}

.single-page-properties {
 display: flex;
 flex-wrap: wrap;
 padding: 0.2em;
}
.properties-panel {
 background-color: rgba(21, 178, 229, 0.06);
 border-radius: 6px;
 border: 1px solid rgb(21, 178, 229);
 padding: 0.8em;
 text-align: center;
 margin: 0.5em;
}
.properties-panel h2 {
 font-size: 0.9em;
 text-transform: uppercase;
 color: #15b2e5;
}
.properties-panel h3 {
 font-size: 1.2em;
 margin: 0;
}

.single-page-des p {
 margin: 0.5em 0;
}
.single-page-img {
 border: 1px solid #e9e9e9;
}
.single-page-header {
 display: flex;
 justify-content: space-between;
}
.mr-10 {
 margin-right: 0.8em;
}
.btn-header {
 border: 1px solid#e9e9e9;
 padding: 0.5em 1em;
 color: #a070dd;
}
.btn-header:first-child {
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
}
.btn-header:last-child {
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
}
.page-header {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 4em 0;
}
.right-side-content h2 {
 font-size: 1.3em;
 color: #a070dd;
}
.right-side-content {
 border-bottom: 1px solid #e9e9e9;
}
.sub-content {
 padding: 1.5em 0;
}
.page-height {
 min-height: 100vh;
}
.page-title {
 color: #a070dd;
 font-size: 1.3em;
 font-weight: 500;
}
