.user-img {
  width: 4em;
  height: 4em;
  object-fit: cover;
  border-radius: 50%;
}

.single-nft-card {
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 1fr minmax(40%, 60%);
  display: grid;
  grid-gap: 2rem;
  margin-top: 2em;
  align-items: center;
  /* max-width: 500px; */
}

.single-nft-card:nth-child(1) {
  margin-top: 0;
}

.single-nft-img {
  width: 100%;
  /* height: 20em; */
  height: 100%;
  object-fit: cover;
}

.single-nft-user-info {
  display: flex;
  align-items: center;
}

.single-nft-user-info h4 {
  padding-left: 1em;
  font-size: 1em;
  font-weight: 600;
}

.single-nft-card h4 {
  font-size: 1em;
  font-weight: 600;
}

.single-nft-card p {
  color: #707275;
  font-weight: 500;
  margin-bottom: 0;
}

.open-status {
  color: #10d876 !important;
  background-color: #cff7e4;
  border-color: #b7f3d6;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.closed-status {
  color: #e50202 !important;
  background-color: #facccc;
  border-color: #f7b3b3;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.active-status {
  color: #7e3af2 !important;
  background-color: #d4ddfa;
  border-color: #beccf8;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.inactive-status {
  color: #adb5bd !important;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.color-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 5px;
  /* margin-right: 0.5em; */
}

.mouth-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 50px;
  margin-right: 0.5em;
}

.eye-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 50px;
  margin-right: 0.5em;
}

@media (max-width: 767.98px) {
  .single-nft-card {
    display: block;
  }
  .single-nft-box {
    padding: 1em;
  }
  .single-nft-card h4 {
    margin-bottom: 0.5em;
  }
  .single-nft-img-sec {
    margin-top: 2em;
  }
  /* .single-nft-img{
        height: 15em;
    } */
}
