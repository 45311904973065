@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800;900&display=swap');
.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
/* .home-page {
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
} */
/* .home-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.5);
} */

.image-title {
  color: rgb(0, 0, 0);
  font-weight: 800;
  font-size: 1em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}

.nft-search-wallet-address-form .form-group{
  margin-bottom: 0;
}

.nft-search-wallet-address-form{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.min-nft-btn-sec{
  padding-left: 1em;
}

.nft-search-wallet-address-form .form-control{
  -webkit-box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.08);
    box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    background-color: #f4f5f7!important;
    border: 0;
    font-weight: 600;
    height: 50px;
    width: 450px;
}

.nft-search-wallet-address-sec{
  padding-top: 3em;
}

.nft-search-wallet-address-form .form-control:focus{
  outline: none!important;
  box-shadow: none!important;
}

.nft-search-wallet-address-form .form-control::placeholder{
  font-size: 13px;
}

.min-nft-btn-sec .mint-nft-btn{
  background: #4272d7;
  height: 50px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  padding: 0 50px;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.min-nft-btn-sec .mint-nft-btn:focus{
  box-shadow: none!important;
outline: none!important;
}

.nft-loot-box{
  /* grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 2rem; */
}

.nft-loot-card{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding:2em;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.nft-loot-img {
  width: 100%;
  height: 12em;
  object-fit: contain;
}

.nft-loot-card p {
  font-size: 15px;
  line-height: 34px;
  color: #007bff;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 0.5em;
  text-decoration: none!important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 6em;
  word-break: break-all;
  /* width: 18.5em; */
}

.nft-images a:hover{
  text-decoration: none!important;
}

.load-more-btn{
  background: #4272d7;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  padding:0.5em 2em;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.load-more-btn:hover,
.load-more-btn:focus{
  color: #fff;
  text-decoration: none!important;
}

.nft-images{
  padding-bottom: 4em;
}

.nft-loot-load-more-btn-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 3em;
}

@media (max-width: 767.98px) {
  .home-header .sub-title{
      display: block;
  }
  .home-header .sub-title li {
      text-align: center;
  }
  .nft-search-wallet-address-form .form-control {
    width: 100%;
  }
  .nft-search-wallet-address-form{
    display: block;
    text-align: center;
  }
  .min-nft-btn-sec{
    padding-left: 0;
    padding-top: 1em;
  }
  .nft-loot-box{
    grid-template-columns: repeat(1, 1fr);
  }
}