@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
/* .home-page {
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
} */
/* .home-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.5);
} */

.image-title {
  color: rgb(0, 0, 0);
  font-weight: 800;
  font-size: 1em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}

.nft-search-wallet-address-form .form-group{
  margin-bottom: 0;
}

.nft-search-wallet-address-form{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.min-nft-btn-sec{
  padding-left: 1em;
}

.nft-search-wallet-address-form .form-control{
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    transition: all .4s ease;
    background-color: #f4f5f7!important;
    border: 0;
    font-weight: 600;
    height: 50px;
    width: 450px;
}

.nft-search-wallet-address-sec{
  padding-top: 3em;
}

.nft-search-wallet-address-form .form-control:focus{
  outline: none!important;
  box-shadow: none!important;
}

.nft-search-wallet-address-form .form-control::placeholder{
  font-size: 13px;
}

.min-nft-btn-sec .mint-nft-btn{
  background: #4272d7;
  height: 50px;
  border-radius: 5px;
  display: inline-block;
  padding: 0 50px;
  transition: all .4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.min-nft-btn-sec .mint-nft-btn:focus{
  box-shadow: none!important;
outline: none!important;
}

.nft-loot-box{
  /* grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 2rem; */
}

.nft-loot-card{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding:2em;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.nft-loot-img {
  width: 100%;
  height: 12em;
  object-fit: contain;
}

.nft-loot-card p {
  font-size: 15px;
  line-height: 34px;
  color: #007bff;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 0.5em;
  text-decoration: none!important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 6em;
  word-break: break-all;
  /* width: 18.5em; */
}

.nft-images a:hover{
  text-decoration: none!important;
}

.load-more-btn{
  background: #4272d7;
  border-radius: 5px;
  display: inline-block;
  padding:0.5em 2em;
  transition: all .4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.load-more-btn:hover,
.load-more-btn:focus{
  color: #fff;
  text-decoration: none!important;
}

.nft-images{
  padding-bottom: 4em;
}

.nft-loot-load-more-btn-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 3em;
}

@media (max-width: 767.98px) {
  .home-header .sub-title{
      display: block;
  }
  .home-header .sub-title li {
      text-align: center;
  }
  .nft-search-wallet-address-form .form-control {
    width: 100%;
  }
  .nft-search-wallet-address-form{
    display: block;
    text-align: center;
  }
  .min-nft-btn-sec{
    padding-left: 0;
    padding-top: 1em;
  }
  .nft-loot-box{
    grid-template-columns: repeat(1, 1fr);
  }
}
/*================================
Template Name: Chat UI
Author: Beno Darry
Author website: http://codegama.com/
Version: 1.0
==================================*/

/*Fonts*/

/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');*/

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

:root {
  --primary-color: #00baff;
  --secondary-color: #ffffff;
  --tertiary-color: #212121;
  --quaternary-color: #9b9b9b;
  --quinary-color: #f5f5f5;
  --senary-color: #ffce53;
}

/*------------- Body -------------*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.25s ease;
  overflow-x: hidden;
  position: relative;
  /*font-family: 'Ubuntu', sans-serif;*/
  font-family: "Poppins", sans-serif;
  color: var(--tertiary-color);
  font-size: 12px;
  letter-spacing: 0.8px;
}

/*-----------------------------
  General
-----------------------------*/

a,
input,
textarea {
  outline: none !important;
  box-shadow: none;
  text-decoration: none !important;
}

.no-border {
  border: 0 !important;
}

.pd-left-zero {
  padding-left: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.padding-right-zero {
  padding-right: 0;
}

.no-margin-auto {
  margin: unset !important;
}

.border-right-zero {
  border-right: 0 !important;
}

.xs-padding {
  padding: 2em 0;
}

.sm-padding {
  padding: 6em 0;
}

.xs-padding-top {
  padding-top: 2em;
}

.space-mg {
  height: 1em;
}

.space-lg {
  height: 2em;
}

.mrg-btm-sec {
  margin-bottom: 2em;
}

.margin-btm-sm {
  margin-bottom: 1.5em;
}

.margin-btm-md {
  margin-bottom: 3em;
}

.margin-top-sm {
  margin-top: 3em;
}

.xl-btn {
  padding: 1.4em 2.2em !important;
  margin: 0 0.5rem;
}

.bg-grey {
  background-color: #f3f4f4;
}

img {
  width: auto;
  display: block;
  max-width: 100%;
  height: auto;
}

.chat-ui-sec img {
  object-fit: cover;
}

.bg-gradient {
  background-image: linear-gradient(90deg, #00f3f7 0%, #109df7 100%) !important;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.85rem 1.5rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  min-width: 170px;
  border-radius: 0;
  white-space: normal;
  transition: all 0.3s;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

/*Dark mode CSS*/

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .switch-slider {
  background-color: #a070dd;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .switch-slider:before {
  transform: translateX(15px);
}

.header-nft-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nft-mode form {
  margin-bottom: 0;
}

.dark-mode {
  background-color: black;
  color: white;
}

.nft-brand-info h2 {
  font-size: 3.5em;
  font-weight: 800;
  background: red;
  background: linear-gradient(90deg, red, #80f);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.margin-top-lg {
  margin-top: 0em;
}

/* .nft-loot-box {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 2rem;
} */

.nft-loot-card {
  padding: 2em;
  border: 1px solid #eff2f7;
  border-radius: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
  text-align: center;
}

.nft-loot-img {
  width: 100%;
  height: 12em;
  object-fit: contain;
}

.nft-loot-card p {
  font-size: 17px;
  line-height: 34px;
  color: #a070dd;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 0.5em;
  text-decoration: none !important;
}

.nft-loot-box a:hover {
  text-decoration: none !important;
}

.load-more-btn {
  background: #a070dd;
  border-radius: 100px;
  display: inline-block;
  padding: 0.5em 2em;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.load-more-btn:hover,
.load-more-btn:focus {
  color: #fff;
  text-decoration: none !important;
}

.nft-images {
  padding-bottom: 4em;
}

.nft-loot-load-more-btn-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 3em;
}

.image-title {
  font-weight: 600;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 2em;
}

.nft-brand-info h4 {
  font-size: 1.6em;
  font-weight: 600;
  padding: 0.5em 0;
}

.nft-brand-info p {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.8;
}

.nft-brand-info h3 {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 0.8em;
}

.nft-loot-banner-img-sec img {
  width: 100%;
}

.nft-social-share-list li {
  border: 1px solid #a070dd;
  border-radius: 100px;
  display: inline-block;
  padding: 0.5em 2em;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
  margin-right: 1em;
}

.nft-social-share-list li:last-child {
  margin-right: 0;
}

.nft-social-share-list li a {
  color: #a070dd;
}

.nft-social-share-link {
  display: flex;
  align-items: center;
}

.nft-social-share-link li img {
  max-width: 4em;
}

.follow-us-btn {
  background: #a070dd;
  border-radius: 100px;
  display: inline-block;
  padding: 0.5em 2em;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 0.5px;
  width: 300px;
  text-align: center;
  margin-bottom: 1em;
}

.follow-us-btn:hover,
.follow-us-btn:active,
.follow-us-btn:focus {
  color: #fff !important;
}

.nft-social-share-link li {
  margin-right: 1em;
}

.nft-social-share-link li:last-child {
  margin-right: 0;
}
.social-icons {
  height: 2em;
}

@media (max-width: 767.98px) {
  .nft-loot-box {
    grid-template-columns: repeat(1, 1fr);
  }
}

.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
.image-title {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1em;
  margin: 3em 0 0em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}

.form-nft {
  border-bottom: 1px solid#ced4da !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}
.left-content {
  padding: 4em;
}
.nft-btn {
  background-color: #f42f54 !important;
  border: none !important;
  padding: 1em 3em !important;
}
.nft-page-form h2 {
  margin: 1em 0 0;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.nft-page-form {
  border: 1px solid #ced4da;
  margin: 2em 0 0;
}
.left-content {
  border-left: 1px solid #ced4da;
}
.mb-6 {
  margin: 0 0 3em !important;
}

.user-img {
  width: 4em;
  height: 4em;
  object-fit: cover;
  border-radius: 50%;
}

.single-nft-card {
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 1fr minmax(40%, 60%);
  display: grid;
  grid-gap: 2rem;
  margin-top: 2em;
  align-items: center;
  /* max-width: 500px; */
}

.single-nft-card:nth-child(1) {
  margin-top: 0;
}

.single-nft-img {
  width: 100%;
  /* height: 20em; */
  height: 100%;
  object-fit: cover;
}

.single-nft-user-info {
  display: flex;
  align-items: center;
}

.single-nft-user-info h4 {
  padding-left: 1em;
  font-size: 1em;
  font-weight: 600;
}

.single-nft-card h4 {
  font-size: 1em;
  font-weight: 600;
}

.single-nft-card p {
  color: #707275;
  font-weight: 500;
  margin-bottom: 0;
}

.open-status {
  color: #10d876 !important;
  background-color: #cff7e4;
  border-color: #b7f3d6;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.closed-status {
  color: #e50202 !important;
  background-color: #facccc;
  border-color: #f7b3b3;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.active-status {
  color: #7e3af2 !important;
  background-color: #d4ddfa;
  border-color: #beccf8;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.inactive-status {
  color: #adb5bd !important;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 0.5em;
}

.color-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 5px;
  /* margin-right: 0.5em; */
}

.mouth-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 50px;
  margin-right: 0.5em;
}

.eye-pro {
  color: #fff !important;
  background-color: #7e3af2;
  border-color: #7e3af2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  padding: 5px 10px;
  border-radius: 50px;
  margin-right: 0.5em;
}

@media (max-width: 767.98px) {
  .single-nft-card {
    display: block;
  }
  .single-nft-box {
    padding: 1em;
  }
  .single-nft-card h4 {
    margin-bottom: 0.5em;
  }
  .single-nft-img-sec {
    margin-top: 2em;
  }
  /* .single-nft-img{
        height: 15em;
    } */
}

.login-sec{
    font-family: "Poppins", sans-serif;
}
.single-page-right-content h4 {
 font-size: 2em;
 margin: 0;
 background: linear-gradient(90deg, red, #80f);
 background-clip: text;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.single-page-btn button {
 margin: 1.3em 0;
 background: #a070dd;
}
.single-page-details {
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.single-page-details p,
.single-page-details h2 {
 font-size: 1em;
}

.single-page-properties {
 display: flex;
 flex-wrap: wrap;
 padding: 0.2em;
}
.properties-panel {
 background-color: rgba(21, 178, 229, 0.06);
 border-radius: 6px;
 border: 1px solid rgb(21, 178, 229);
 padding: 0.8em;
 text-align: center;
 margin: 0.5em;
}
.properties-panel h2 {
 font-size: 0.9em;
 text-transform: uppercase;
 color: #15b2e5;
}
.properties-panel h3 {
 font-size: 1.2em;
 margin: 0;
}

.single-page-des p {
 margin: 0.5em 0;
}
.single-page-img {
 border: 1px solid #e9e9e9;
}
.single-page-header {
 display: flex;
 justify-content: space-between;
}
.mr-10 {
 margin-right: 0.8em;
}
.btn-header {
 border: 1px solid#e9e9e9;
 padding: 0.5em 1em;
 color: #a070dd;
}
.btn-header:first-child {
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
}
.btn-header:last-child {
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
}
.page-header {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 4em 0;
}
.right-side-content h2 {
 font-size: 1.3em;
 color: #a070dd;
}
.right-side-content {
 border-bottom: 1px solid #e9e9e9;
}
.sub-content {
 padding: 1.5em 0;
}
.page-height {
 min-height: 100vh;
}
.page-title {
 color: #a070dd;
 font-size: 1.3em;
 font-weight: 500;
}

