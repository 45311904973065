@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.home-page {
  font-family: "Poppins", sans-serif;
}
.sub-title {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.sub-title li {
  padding: 0 1em;
  align-items: center;
  justify-content: center;
}
.sub-title li a {
  font-weight: 600;
}
.image-card-link a {
  font-weight: 600;
}
.sec-title {
  color: rgb(0, 0, 0);
  font-size: 5em;
  font-weight: 700;
}
.navbar-light .navbar-brand {
  color: rgb(0, 0, 0) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.sec-para {
  text-align: center;
  color: rgb(0, 0, 0);
}
.home-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 20px;
  position: relative;
}
.image-title {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1em;
  margin: 3em 0 0em;
}
.sec-images {
  width: 400px;
  height: 300px;
}

.nft-images {
  position: relative;
}
.image-card {
  margin: 1em 0;
}
